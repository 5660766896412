import { BarChartComponent, BarChartProps } from 'src/components/ui-components';
import ReportingWidgetCard from 'src/screens/Home/components/ReportingWidgetCard/ReportingWidgetCard';
import Loading from '../MilestoneFeed/components/Loading/Loading';
import Error from '../MilestoneFeed/components/Error/Error';

interface IReportingWidgetWithChart {
  name: string;
  title: string;
  data: {}[];
  infoIconText: string;
  xAxisDataKey: string;
  yAxisDataKey: string;
  yAxisConfigs?: object[];
  toolTipConfigs?: object;
  barPropsDataKey: string[];
  isEmpty: boolean;
  isError: boolean;
  isLoading: boolean;
}

// DRO: To utilize the Rechart bar chart the barProps should return an object of dataKey
// For eg. {[{ dataKey: '2022' }, { dataKey: '2023' }]}
const parseBarPropsData = (dataKeyList: string[]) => {
  const barProps = dataKeyList.map((key) => ({
    dataKey: key,
  }));
  return barProps;
};

const ReportBarChart = ({
  xAxisKey,
  yAxisKey,
  yAxisConfigs,
  tooltipConfigs,
  data,
  barProps,
}: BarChartProps) => (
  // not dynamic-ready atm. Need to define dataKey for each bar
  <div style={{ width: '100%', height: 300 }}>
    <BarChartComponent
      data={data}
      surfaceMargin={{ top: 20, right: 20, bottom: 20, left: 32 }}
      barProps={barProps}
      tooltip
      tooltipConfigs={tooltipConfigs}
      cartesianGrid
      legend
      xAxisKey={xAxisKey}
      yAxisKey={yAxisKey}
      yAxisConfigs={yAxisConfigs}
    />
  </div>
);
export const ReportingWidgetWithChart = ({
  name,
  title,
  data,
  infoIconText,
  xAxisDataKey,
  yAxisDataKey,
  yAxisConfigs,
  toolTipConfigs,
  barPropsDataKey,
  isEmpty,
  isError,
  isLoading,
}: IReportingWidgetWithChart) => {
  const renderWidgetContentBasedOnState = () => {
    if (isLoading) {
      return <Loading />;
    }
    if (isError) {
      return <Error />;
    }
    if (isEmpty) {
      return <div />;
    }
    return (
      <div data-automation-id={`ReportingWidgetBarChart-${name}`}>
        <ReportBarChart
          xAxisKey={xAxisDataKey}
          yAxisKey={yAxisDataKey}
          yAxisConfigs={yAxisConfigs || []}
          data={data}
          tooltipConfigs={toolTipConfigs}
          barProps={parseBarPropsData(barPropsDataKey)}
        />
      </div>
    );
  };
  return (
    <ReportingWidgetCard name={name} title={title} infoIconText={infoIconText} hasAction>
      {renderWidgetContentBasedOnState()}
    </ReportingWidgetCard>
  );
};

export default ReportingWidgetWithChart;
