import { useGetLocale } from 'src/components/global/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';
import { useGetInvoicedPerMonthReportingWidget } from 'src/apis/reportingWidgetsAPI';
import { IInvoicedPerMonthWidgetData } from 'src/apis/types/invoicedPerMonthAPI';
import ReportingWidgetWithChart from '../ReportingWidgetWithChart';

const getBarProps = (data: IInvoicedPerMonthWidgetData[], key: string, key2: string) => {
  const dataKeyList = data.map((item: any) => item[key]);
  const dataKey2List = data.map((item: any) => item[key2]);
  const sortedDataKeyCollection = dataKeyList.concat(dataKey2List).sort();
  // to remove duplicates
  const barProps: string[] = Array.from(new Set(sortedDataKeyCollection));

  return barProps;
};

export const InvoicedPerMonthReportingWidget = () => {
  const locale = useGetLocale();
  const X_AXIS_DATAKEY = 'value';
  const { t } = useTranslation('reportingWidgets');
  const { widgetName, widgetData, currencyAbb, isError, isLoading } =
    useGetInvoicedPerMonthReportingWidget();

  const labelTickFormatter = (tick: any) => tick.toLocaleString(locale);
  const labelTooltipFormatter = (value: number) => value.toLocaleString(locale);

  const parseChartData = (data: IInvoicedPerMonthWidgetData[]) => {
    const chartData = data.map((item) => {
      const chartObject = {
        value: translationAnyText(t, `Month${item.month}`),
        [item.year]: item.invoicedAmount,
        [item.lastYear]: item.invoicedAmountLastYear,
      };
      return chartObject;
    });
    return chartData;
  };

  return (
    <ReportingWidgetWithChart
      name={widgetName}
      title={t('InvoicedPerMonth')}
      data={parseChartData(widgetData)}
      infoIconText={t('InvoicedPerMonthWidgetDescription')}
      xAxisDataKey={X_AXIS_DATAKEY}
      yAxisDataKey=""
      yAxisConfigs={[
        {
          tickFormatter: labelTickFormatter,
          label: { value: currencyAbb, angle: -90.1, position: 'insideLeft', offset: -25 },
        },
      ]}
      toolTipConfigs={{ formatter: labelTooltipFormatter }}
      barPropsDataKey={getBarProps(widgetData, 'year', 'lastYear')}
      isEmpty={widgetData.length <= 0}
      isError={isError}
      isLoading={isLoading}
    />
  );
};

export default InvoicedPerMonthReportingWidget;
